var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-button",
        {
          class: _vm.all,
          attrs: { color: "primary", type: _vm.all.active ? "line" : "filled" },
          on: {
            click: function($event) {
              return _vm.changeVisible("all")
            }
          }
        },
        [_vm._v("\n    Tüm Başlıklar\n  ")]
      ),
      _c(
        "vs-button",
        {
          class: _vm.approved,
          attrs: {
            color: "success",
            type: _vm.approved.active ? "line" : "filled"
          },
          on: {
            click: function($event) {
              return _vm.changeVisible("approved")
            }
          }
        },
        [_vm._v("\n    Onaylanmış Başlıklar\n  ")]
      ),
      _c(
        "vs-button",
        {
          class: _vm.waiting,
          attrs: {
            color: "warning",
            type: _vm.waiting.active ? "line" : "filled"
          },
          on: {
            click: function($event) {
              return _vm.changeVisible("waiting")
            }
          }
        },
        [_vm._v("\n    Bekleyen Başlıklar\n  ")]
      ),
      _c(
        "vs-button",
        {
          class: _vm.deleted,
          attrs: {
            color: "danger",
            type: _vm.deleted.active ? "line" : "filled"
          },
          on: {
            click: function($event) {
              return _vm.changeVisible("deleted")
            }
          }
        },
        [_vm._v("\n    Silinmiş Başlıklar\n  ")]
      ),
      _c(
        "vs-button",
        {
          class: _vm.merge,
          attrs: { color: "dark", type: _vm.merge.active ? "line" : "filled" },
          on: {
            click: function($event) {
              return _vm.changeVisible("merge")
            }
          }
        },
        [
          _vm._v(
            "\n    Birleştirilecek Başlıklar(" +
              _vm._s(
                this.$store.state.merge_topic.length
                  ? this.$store.state.merge_topic.length
                  : "0"
              ) +
              ")\n  "
          )
        ]
      ),
      _c("topic-list", {
        attrs: { propTopicData: _vm.topicData, topicType: _vm.type },
        on: { refreshData: _vm.refreshData }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }