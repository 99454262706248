<template>
  <div id="data-list-list-view" class="data-list-container">
    <vs-table id="topiclist" v-model="selected" @selected="updateTopic" pagination :max-items="itemsPerPage" search :data="topics">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center">
          <vs-button @click="mergeTopics()" color="success" type="border" v-if="topicType === 'merge'">Birleştir</vs-button>
          <!--  <vs-button @click="clearAllMergeTopics()" color="primary" type="border" v-if="topicType==='merge'">Bütün Başlıkları Temizle</vs-button> -->
          <!-- ACTION - DROPDOWN -->
          <!-- <vs-dropdown vs-trigger-click class="cursor-pointer mr-4 mb-4" v-if="topicType=='deleted'">

            <div class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32">
              <span class="mr-2">Actions</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>

              <vs-dropdown-item>
                <span>Delete</span>
              </vs-dropdown-item>
              <vs-dropdown-item>
                <span>Archive</span>
              </vs-dropdown-item>
              <vs-dropdown-item>
                <span>Print</span>
              </vs-dropdown-item>
              <vs-dropdown-item>
                <span>Another Action</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown> -->
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <!-- <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ topics.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : topics.length }} of {{ topics.length }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div> -->
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th>Aksiyon</vs-th>
        <vs-th sort-key="order_status">Durum</vs-th>
        <vs-th sort-key="topic">Başlık</vs-th>
        <vs-th sort-key="onedioId">Onedio ID</vs-th>
        <vs-th sort-key="createDate">Oluşturulma Tarihi</vs-th>
        <vs-th sort-key="updateDate">Güncellenme Tarihi</vs-th>
        <vs-th sort-key="publishDate">Yayınlanma Tarihi</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td id="action">
              <vs-button @click="routertoDio($event, tr.id)" color="primary" type="border">Dioları Gör</vs-button>
              <vs-button @click="approveTopic($event, tr.id)" color="success" type="border" v-if="tr.state.includes('waiting')">
                Onayla
              </vs-button>
              <vs-button
                v-if="topicType !== 'merge' && !merge_topics.includes(tr.id)"
                @click="mergeTopic($event, tr.id)"
                color="warning"
                type="border"
              >
                Birleştir'e Ekle
              </vs-button>
              <vs-button @click="restoreTopic($event, tr.id)" color="dark" type="border" v-if="tr.state.includes('deleted')">
                Geri Getir
              </vs-button>
              <vs-button
                @click="deleteTopic($event, tr.id)"
                color="danger"
                type="border"
                v-else-if="tr.state.includes('waiting') || tr.state.includes('approved')"
              >
                Sil
              </vs-button>
              <vs-button
                v-if="topicType === 'merge' && merge_topics.includes(tr.id)"
                @click="removeMergeTopic($event, tr.id)"
                color="warning"
                type="border"
              >
                Birleştirme
              </vs-button>
            </vs-td>

            <vs-td id="status">
              <div :data="item" :key="index" v-for="(item, index) in tr.state">
                <vs-chip :color="getState(item)" class="product-order-status">{{ getStateText(item) }}</vs-chip>
              </div>
            </vs-td>

            <vs-td id="topic" :data="tr.topic">
              {{ tr.topic }}
            </vs-td>

            <vs-td id="onedioID">
              <p class="product-name font-medium">{{ tr.onedioId }}</p>
            </vs-td>

            <vs-td id="createDate">
              <p class="product-name font-medium">
                {{ tr.createDate | moment('DD-MM-YYYY') }}
                <br />
                {{ tr.createDate | moment('HH:mm') }}
              </p>
            </vs-td>

            <vs-td id="updateDate">
              <p class="product-name font-medium">
                {{ tr.updateDate | moment('DD-MM-YYYY') }}
                <br />
                {{ tr.updateDate | moment('HH:mm') }}
              </p>
            </vs-td>

            <vs-td id="publishDate">
              <p class="product-name font-medium">
                {{ tr.publishDate | moment('DD-MM-YYYY') }}
                <br />
                {{ tr.publishDate | moment('HH:mm') }}
              </p>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <topic-dialog :activePrompt="activateDialog" @updateActive="changePromptActive" :mergeTopics="optionList"></topic-dialog>
    <topic-update
      :activeUpdate="activateUpdate"
      @activityUpdate="changeUpdateActive"
      :updatetopicId="topicId"
      :topic="topicName"
      :topicType="topicType"
      @refreshData="refreshData"
    ></topic-update>
  </div>
</template>

<script>
import TopicActions from './TopicActions';
import TopicDialog from './TopicDialog';
import TopicUpdate from './TopicUpdate';

export default {
  name: 'TopicList',
  props: ['propTopicData', 'topicType'],
  data() {
    return {
      activateUpdate: false,
      topicId: '',
      activateEntry: false,
      optionList: [],
      activateDialog: false,
      selected: [],
      topics: [],
      itemsPerPage: 10,
      isMounted: false,
      topicName: '',
      updatetopic: true,
      merge_topics: []
    };
  },
  components: {
    TopicDialog,
    TopicUpdate
  },
  computed: {},
  methods: {
    refreshData() {
      this.$emit('refreshData', this.topicType);
    },
    changeUpdateActive(newValue) {
      this.activateUpdate = newValue;
    },
    changePromptActive(newValue) {
      this.activateDialog = newValue;
    },
    changeEntryActive(newValue) {
      this.activateEntry = newValue;
    },
    async clearAllMergeTopics() {
      await this.$store.dispatch('remove_all_merge_topic_id');
      await this.$store.dispatch('remove_all_merge_topic');
      //this.$emit('refreshData',this.topicType)
    },
    async removeMergeTopic($event, itemID) {
      event.stopPropagation();
      let item = await TopicActions.getTopicByID(itemID);
      await this.$store.dispatch('remove_merge_topic_id', itemID);
      this.merge_topics = await this.$store.state.merge_topic_ids;
      await this.$store.dispatch('remove_merge_topic', item);
      //this.$emit('refreshData',this.topicType)
    },
    async mergeTopic(event, itemID) {
      event.stopPropagation();
      let item = await TopicActions.getTopicByID(itemID);
      await this.$store.dispatch('merge_topic_id', itemID);
      this.merge_topics = await this.$store.state.merge_topic_ids;
      await this.$store.dispatch('merge_topic', item);
      /* let items=localStorage.getItem('merge_topic')? JSON.parse(localStorage.getItem('merge_topic')) : []
      items.push(item)
      localStorage.setItem('merge_topic',JSON.stringify(items))
      let items_id=localStorage.getItem('merge_topic_id')? JSON.parse(localStorage.getItem('merge_topic_id')) : []
      items_id.push(itemID)
      localStorage.setItem('merge_topic',JSON.stringify(items)) */
    },
    mergeTopics() {
      var list = [];
      this.$store.state.merge_topic.forEach(function(item) {
        var newOption = {
          text: item.topic,
          value: item.id
        };
        list.push(newOption);
      });
      this.optionList = list;
      this.activateDialog = true;
    },
    updateTopic(selectedItem) {
      if (this.updatetopic) {
        this.topicId = selectedItem.id;
        this.topicName = selectedItem.topic;
        this.activateUpdate = true;
      }
    },
    async approveTopic(event, id) {
      event.stopPropagation();
      if (confirm('Başlığı onaylamak istediğinize emin misiniz?')) {
        await TopicActions.approveTopic(id);
        this.$emit('refreshData', this.topicType);
      }
    },
    async restoreTopic(event, id) {
      event.stopPropagation();
      if (confirm('Başlığı geri getirmek istediğinize emin misiniz?')) {
        var idList = [];
        idList.push(id);
        await TopicActions.restoreTopic(idList);
        this.$emit('refreshData', this.topicType);
      }
    },
    async deleteTopic(event, id) {
      event.stopPropagation();
      if (confirm('Başlığı silmek istediğinize emin misiniz?')) {
        await TopicActions.deleteTopic(id);
        this.$emit('refreshData', this.topicType);
      }
    },
    getState(state) {
      if (state == 'deleted') {
        return 'danger';
      }
      if (state == 'waiting') {
        return 'warning';
      }
      if (state == 'approved') {
        return 'success';
      }
      if (state == 'restored') {
        return 'light';
      }
    },
    getStateText(state) {
      if (state == 'deleted') {
        return 'Silinmiş';
      }
      if (state == 'waiting') {
        return 'Bekleyen';
      }
      if (state == 'approved') {
        return 'Onaylanmış';
      }
      if (state == 'restored') {
        return 'Geri Getirilmiş';
      }
    },

    routertoDio(event, topicID) {
      event.stopPropagation();
      this.$store.dispatch('topic_entry', topicID);
      this.$router.push('/dashboard/entry');
    }
  },
  async created() {
    this.merge_topics = await this.$store.state.merge_topic_ids;
  },
  mounted() {
    this.isMounted = true;
  },
  watch: {
    propTopicData(newData) {
      this.topics = newData;
    }
  }
};
</script>

<style lang="scss" scoped>
.con-vs-chip {
  font-size: 1.12rem;
}
.vs-button {
  width: 150px;
}
#topic {
  width: 550px;
  font-size: 1.12rem;
}
#createDate {
  width: 130px;
  text-align: center;
}
#updateDate {
  width: 130px;
  text-align: center;
}
#publishDate {
  width: 130px;
  text-align: center;
}
#data-list-list-view {
  .vs-con-table {
    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          //width: 15px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
