var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-popup",
    {
      staticClass: "holamundo",
      attrs: { title: "Başlığı güncelle", active: _vm.activity },
      on: {
        "update:active": function($event) {
          _vm.activity = $event
        },
        close: _vm.close
      }
    },
    [
      _c(
        "div",
        [
          _c("vs-input", {
            staticClass: "inputx",
            staticStyle: { width: "560px" },
            attrs: { placeholder: _vm.topicName },
            model: {
              value: _vm.topicName,
              callback: function($$v) {
                _vm.topicName = $$v
              },
              expression: "topicName"
            }
          })
        ],
        1
      ),
      _c(
        "vs-button",
        {
          staticClass: "mt-2",
          staticStyle: { "text-align": "center" },
          on: { click: _vm.submit }
        },
        [_vm._v("\n    Güncelle\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }