var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "data-list-container",
      attrs: { id: "data-list-list-view" }
    },
    [
      _c(
        "vs-table",
        {
          attrs: {
            id: "topiclist",
            pagination: "",
            "max-items": _vm.itemsPerPage,
            search: "",
            data: _vm.topics
          },
          on: { selected: _vm.updateTopic },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var data = ref.data
                return [
                  _c(
                    "tbody",
                    _vm._l(data, function(tr, indextr) {
                      return _c(
                        "vs-tr",
                        { key: indextr, attrs: { data: tr } },
                        [
                          _c(
                            "vs-td",
                            { attrs: { id: "action" } },
                            [
                              _c(
                                "vs-button",
                                {
                                  attrs: { color: "primary", type: "border" },
                                  on: {
                                    click: function($event) {
                                      return _vm.routertoDio($event, tr.id)
                                    }
                                  }
                                },
                                [_vm._v("Dioları Gör")]
                              ),
                              tr.state.includes("waiting")
                                ? _c(
                                    "vs-button",
                                    {
                                      attrs: {
                                        color: "success",
                                        type: "border"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.approveTopic($event, tr.id)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              Onayla\n            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.topicType !== "merge" &&
                              !_vm.merge_topics.includes(tr.id)
                                ? _c(
                                    "vs-button",
                                    {
                                      attrs: {
                                        color: "warning",
                                        type: "border"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.mergeTopic($event, tr.id)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              Birleştir'e Ekle\n            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              tr.state.includes("deleted")
                                ? _c(
                                    "vs-button",
                                    {
                                      attrs: { color: "dark", type: "border" },
                                      on: {
                                        click: function($event) {
                                          return _vm.restoreTopic($event, tr.id)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              Geri Getir\n            "
                                      )
                                    ]
                                  )
                                : tr.state.includes("waiting") ||
                                  tr.state.includes("approved")
                                ? _c(
                                    "vs-button",
                                    {
                                      attrs: {
                                        color: "danger",
                                        type: "border"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteTopic($event, tr.id)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              Sil\n            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.topicType === "merge" &&
                              _vm.merge_topics.includes(tr.id)
                                ? _c(
                                    "vs-button",
                                    {
                                      attrs: {
                                        color: "warning",
                                        type: "border"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.removeMergeTopic(
                                            $event,
                                            tr.id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              Birleştirme\n            "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "vs-td",
                            { attrs: { id: "status" } },
                            _vm._l(tr.state, function(item, index) {
                              return _c(
                                "div",
                                { key: index, attrs: { data: item } },
                                [
                                  _c(
                                    "vs-chip",
                                    {
                                      staticClass: "product-order-status",
                                      attrs: { color: _vm.getState(item) }
                                    },
                                    [_vm._v(_vm._s(_vm.getStateText(item)))]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _c(
                            "vs-td",
                            { attrs: { id: "topic", data: tr.topic } },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(tr.topic) +
                                  "\n          "
                              )
                            ]
                          ),
                          _c("vs-td", { attrs: { id: "onedioID" } }, [
                            _c(
                              "p",
                              { staticClass: "product-name font-medium" },
                              [_vm._v(_vm._s(tr.onedioId))]
                            )
                          ]),
                          _c("vs-td", { attrs: { id: "createDate" } }, [
                            _c(
                              "p",
                              { staticClass: "product-name font-medium" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("moment")(
                                        tr.createDate,
                                        "DD-MM-YYYY"
                                      )
                                    ) +
                                    "\n              "
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("moment")(tr.createDate, "HH:mm")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ]),
                          _c("vs-td", { attrs: { id: "updateDate" } }, [
                            _c(
                              "p",
                              { staticClass: "product-name font-medium" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("moment")(
                                        tr.updateDate,
                                        "DD-MM-YYYY"
                                      )
                                    ) +
                                    "\n              "
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("moment")(tr.updateDate, "HH:mm")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ]),
                          _c("vs-td", { attrs: { id: "publishDate" } }, [
                            _c(
                              "p",
                              { staticClass: "product-name font-medium" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("moment")(
                                        tr.publishDate,
                                        "DD-MM-YYYY"
                                      )
                                    ) +
                                    "\n              "
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("moment")(tr.publishDate, "HH:mm")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    }),
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.selected,
            callback: function($$v) {
              _vm.selected = $$v
            },
            expression: "selected"
          }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex flex-wrap-reverse items-center flex-grow justify-between",
              attrs: { slot: "header" },
              slot: "header"
            },
            [
              _c(
                "div",
                { staticClass: "flex flex-wrap-reverse items-center" },
                [
                  _vm.topicType === "merge"
                    ? _c(
                        "vs-button",
                        {
                          attrs: { color: "success", type: "border" },
                          on: {
                            click: function($event) {
                              return _vm.mergeTopics()
                            }
                          }
                        },
                        [_vm._v("Birleştir")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "vs-dropdown",
                {
                  staticClass: "cursor-pointer mb-4 mr-4",
                  attrs: { "vs-trigger-click": "" }
                },
                [
                  _c(
                    "vs-dropdown-menu",
                    [
                      _c(
                        "vs-dropdown-item",
                        {
                          on: {
                            click: function($event) {
                              _vm.itemsPerPage = 4
                            }
                          }
                        },
                        [_c("span", [_vm._v("4")])]
                      ),
                      _c(
                        "vs-dropdown-item",
                        {
                          on: {
                            click: function($event) {
                              _vm.itemsPerPage = 10
                            }
                          }
                        },
                        [_c("span", [_vm._v("10")])]
                      ),
                      _c(
                        "vs-dropdown-item",
                        {
                          on: {
                            click: function($event) {
                              _vm.itemsPerPage = 15
                            }
                          }
                        },
                        [_c("span", [_vm._v("15")])]
                      ),
                      _c(
                        "vs-dropdown-item",
                        {
                          on: {
                            click: function($event) {
                              _vm.itemsPerPage = 20
                            }
                          }
                        },
                        [_c("span", [_vm._v("20")])]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", [_vm._v("Aksiyon")]),
              _c("vs-th", { attrs: { "sort-key": "order_status" } }, [
                _vm._v("Durum")
              ]),
              _c("vs-th", { attrs: { "sort-key": "topic" } }, [
                _vm._v("Başlık")
              ]),
              _c("vs-th", { attrs: { "sort-key": "onedioId" } }, [
                _vm._v("Onedio ID")
              ]),
              _c("vs-th", { attrs: { "sort-key": "createDate" } }, [
                _vm._v("Oluşturulma Tarihi")
              ]),
              _c("vs-th", { attrs: { "sort-key": "updateDate" } }, [
                _vm._v("Güncellenme Tarihi")
              ]),
              _c("vs-th", { attrs: { "sort-key": "publishDate" } }, [
                _vm._v("Yayınlanma Tarihi")
              ])
            ],
            1
          )
        ],
        2
      ),
      _c("topic-dialog", {
        attrs: {
          activePrompt: _vm.activateDialog,
          mergeTopics: _vm.optionList
        },
        on: { updateActive: _vm.changePromptActive }
      }),
      _c("topic-update", {
        attrs: {
          activeUpdate: _vm.activateUpdate,
          updatetopicId: _vm.topicId,
          topic: _vm.topicName,
          topicType: _vm.topicType
        },
        on: {
          activityUpdate: _vm.changeUpdateActive,
          refreshData: _vm.refreshData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }