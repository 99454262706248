<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <vs-button v-bind:class="all" color="primary" :type="all.active ? 'line' : 'filled'" @click="changeVisible('all')">
      Tüm Başlıklar
    </vs-button>
    <vs-button v-bind:class="approved" color="success" :type="approved.active ? 'line' : 'filled'" @click="changeVisible('approved')">
      Onaylanmış Başlıklar
    </vs-button>
    <vs-button v-bind:class="waiting" color="warning" :type="waiting.active ? 'line' : 'filled'" @click="changeVisible('waiting')">
      Bekleyen Başlıklar
    </vs-button>
    <vs-button v-bind:class="deleted" color="danger" :type="deleted.active ? 'line' : 'filled'" @click="changeVisible('deleted')">
      Silinmiş Başlıklar
    </vs-button>
    <vs-button v-bind:class="merge" color="dark" :type="merge.active ? 'line' : 'filled'" @click="changeVisible('merge')">
      Birleştirilecek Başlıklar({{ this.$store.state.merge_topic.length ? this.$store.state.merge_topic.length : '0' }})
    </vs-button>
    <topic-list :propTopicData="topicData" :topicType="type" @refreshData="refreshData"></topic-list>
  </div>
</template>

<script>
import TopicActions from './TopicActions';
import TopicList from './TopicList';
export default {
  data() {
    return {
      topicData: [],
      type: 'all',
      isMounted: false,
      all: {
        active: true
      },
      waiting: {
        active: false
      },
      deleted: {
        active: false
      },
      approved: {
        active: false
      },
      merge: {
        active: false
      }
    };
  },
  components: {
    TopicList
  },
  computed: {},
  methods: {
    async formatData(data) {
      var filtered_data = data;
      for (var i = 0; i < data.length; i++) {
        filtered_data[i]['state'] = [];
        if (data[i].deleted) {
          filtered_data[i].state.push('deleted');
        }
        if (data[i].approved) {
          filtered_data[i].state.push('approved');
        }
        if (!data[i].approved) {
          filtered_data[i].state.push('waiting');
        }
        if (data[i].published) {
          filtered_data[i].satae.push('restored');
        }
      }
      return await filtered_data;
    },
    async refreshData(newValue) {
      await this.changeVisible(newValue);
    },
    async approvedFormat(data) {
      var filtered_data = [];
      for (var i = 0; i < data.length; i++) {
        if (data[i].approved) {
          filtered_data.push(data[i]);
        }
      }
      return await filtered_data;
    },
    async allTopics() {
      const thisIns = this;
      thisIns.$vs.loading();
      let response = await TopicActions.getAllTopics();
      await thisIns.formatData(response).then((result) => {
        thisIns.topicData = result;
        thisIns.$vs.loading.close();
      });
      thisIns.type = 'all';
      localStorage.setItem('route', 'all');
    },
    async approvedTopics() {
      const thisIns = this;
      thisIns.$vs.loading();
      let response = await TopicActions.getAllTopics('approved');
      let temp = await thisIns.approvedFormat(response);
      await thisIns.formatData(temp).then((result) => {
        thisIns.topicData = result;
        thisIns.$vs.loading.close();
      });
      thisIns.type = 'approved';
      localStorage.setItem('route', 'approved');
    },
    async waitingTopics() {
      const thisIns = this;
      thisIns.$vs.loading();
      let response = await TopicActions.getWaitingTopics('waiting');
      await thisIns.formatData(response).then((result) => {
        thisIns.topicData = result;
        thisIns.$vs.loading.close();
      });
      thisIns.type = 'waiting';
      localStorage.setItem('route', 'waiting');
    },
    async deletedTopics() {
      const thisIns = this;
      thisIns.$vs.loading();
      let response = await TopicActions.getDeletedTopics('deleted');
      await thisIns.formatData(response).then((result) => {
        thisIns.topicData = result;
        thisIns.$vs.loading.close();
      });
      thisIns.type = 'deleted';
      localStorage.setItem('route', 'deleted');
    },
    async mergeTopics() {
      const thisIns = this;
      thisIns.$vs.loading();
      let response = this.$store.state.merge_topic;
      await thisIns.formatData(response).then((result) => {
        thisIns.topicData = result;
        thisIns.$vs.loading.close();
      });
      thisIns.type = 'merge';
      localStorage.setItem('route', 'merge');
    },
    async changeVisible(visible) {
      if (visible === 'all') {
        this.all.active = await true;
        this.waiting.active = await false;
        this.deleted.active = await false;
        this.approved.active = await false;
        this.merge.active = await false;
        await this.allTopics();
      } else if (visible === 'approved') {
        this.all.active = await false;
        this.waiting.active = await false;
        this.deleted.active = await false;
        this.approved.active = await true;
        this.merge.active = await false;
        await this.approvedTopics();
      } else if (visible === 'waiting') {
        this.all.active = await false;
        this.waiting.active = await true;
        this.deleted.active = await false;
        this.approved.active = await false;
        this.merge.active = await false;
        await this.waitingTopics();
      } else if (visible === 'deleted') {
        this.all.active = await false;
        this.waiting.active = await false;
        this.deleted.active = await true;
        this.approved.active = await false;
        this.merge.active = await false;
        await this.deletedTopics();
      } else if (visible === 'merge') {
        this.all.active = await false;
        this.waiting.active = await false;
        this.deleted.active = await false;
        this.approved.active = await false;
        this.merge.active = await true;
        await this.mergeTopics();
      }
    }
  },
  async created() {
    await this.$store.dispatch('topic_entry', '');
    await this.changeVisible(localStorage.getItem('route') || 'all');
    //await this.allTopics()
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<style lang="scss" scoped>
.vs-button:hover {
  box-shadow: 0px 0px 0px;
}

#data-list-list-view {
  .vs-con-table {
    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
