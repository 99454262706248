<template>
  <vs-prompt @cancel="val = ''" @accept="acceptAlert" @close="close" :active.sync="active" title="Birleştir">
    <div>
      <vs-select class="selectExample" label="Birleştirilecek başlığı seçiniz." v-model="selectedOption">
        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in options" />
      </vs-select>
    </div>
  </vs-prompt>
</template>

<script>
import TopicActions from './TopicActions';
export default {
  props: ['activePrompt', 'mergeTopics'],
  data() {
    return {
      options: [],
      active: false,
      selectedOption: ''
    };
  },
  computed: {},
  methods: {
    acceptAlert() {
      //birleştirilecek ID
      var mergedTopicID = this.selectedOption;
      var others = []; //birleştirilenlerin id'leri
      this.options.forEach(function(item) {
        if (item.value !== mergedTopicID) {
          others.push(item.value);
        }
      });
      var body = {
        mergedTopicId: mergedTopicID,
        otherTopics: others
      };
      TopicActions.mergeTopics(body);
      this.$vs.notify({
        color: 'success',
        text: 'Başlıklar başarıyla birleştirildi.'
      });
    },
    close() {
      this.$vs.notify({
        color: 'danger',
        text: 'Başlıklar birleştirilemedi.'
      });
      this.$emit('updateActive', false);
    }
  },
  watch: {
    activePrompt(newData) {
      this.active = newData;
    },
    mergeTopics(newData) {
      this.options = newData;
    }
  }
};
</script>
