var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-prompt",
    {
      attrs: { active: _vm.active, title: "Birleştir" },
      on: {
        cancel: function($event) {
          _vm.val = ""
        },
        accept: _vm.acceptAlert,
        close: _vm.close,
        "update:active": function($event) {
          _vm.active = $event
        }
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "vs-select",
            {
              staticClass: "selectExample",
              attrs: { label: "Birleştirilecek başlığı seçiniz." },
              model: {
                value: _vm.selectedOption,
                callback: function($$v) {
                  _vm.selectedOption = $$v
                },
                expression: "selectedOption"
              }
            },
            _vm._l(_vm.options, function(item, index) {
              return _c("vs-select-item", {
                key: index,
                attrs: { value: item.value, text: item.text }
              })
            }),
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }