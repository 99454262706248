import axios from 'axios';
export default {
  name: 'TopicActions',
  base: 'http://dio-backend-staging-env3.eu-west-1.elasticbeanstalk.com/v1/1/4/1',
  async getAllTopics(type = 'all') {
    let result = [];
    var token = localStorage.getItem('user');
    await axios
      .get(`${this.base}/topic?type=${type}&page=1&perPage=10`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(function(response) {
        result = response.data.data;
      });
    return result;
  },
  async getWaitingTopics() {
    let result = [];
    var token = localStorage.getItem('user');
    await axios
      .get(`${this.base}/topic?type=waiting&page=1&perPage=10`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(function(response) {
        result = response.data.data;
      });
    return result;
  },
  async getDeletedTopics() {
    let result = [];
    var token = localStorage.getItem('user');
    await axios
      .get(`${this.base}/topic?type=deleted&page=1&perPage=10`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(function(response) {
        result = response.data.data;
      });
    return result;
  },
  async deleteTopic(itemID) {
    let result = [];
    var token = localStorage.getItem('user');
    await axios
      .delete(`${this.base}/topic/delete/soft/${itemID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(function(response) {
        result = response.data.data;
      });
    return result;
  },
  async restoreTopic(itemIDs) {
    var restored_entries = [];
    restored_entries = itemIDs;
    var body = {
      topicIds: restored_entries
    };
    let result = [];
    var token = localStorage.getItem('user');
    await axios
      .post(`${this.base}/topic/restore`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(function(response) {
        result = response.data.data;
      });
    return result;
  },
  async approveTopic(itemID) {
    let result = [];
    var token = localStorage.getItem('user');
    await axios
      .post(
        `${this.base}/topic/approve?topicId=` + itemID,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      )
      .then(function(response) {
        result = response.data.data;
      });
    return result;
  },
  async mergeTopics(requestBody) {
    let result = [];
    var token = localStorage.getItem('user');
    await axios
      .post(`${this.base}/topic/merge`, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(function(response) {
        result = response.data.data;
      });
    return result;
  },
  async updateTopic(requestBody) {
    let result = [];
    var token = localStorage.getItem('user');
    await axios
      .put(`${this.base}/topic/update`, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(function(response) {
        result = response.data.data;
      });
    return result;
  },
  async getTopicByID(itemID) {
    let result;
    var token = localStorage.getItem('user');
    await axios
      .get(`${this.base}/topic/getByID/` + itemID, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(function(response) {
        result = response.data.data;
      });
    return result;
  }
};
