<template>
  <vs-popup class="holamundo" title="Başlığı güncelle" :active.sync="activity" @close="close">
    <div>
      <vs-input style="width:560px" class="inputx" v-model="topicName" :placeholder="topicName"></vs-input>
    </div>
    <vs-button style="text-align: center" class="mt-2" @click="submit">
      Güncelle
    </vs-button>
  </vs-popup>
</template>

<script>
import TopicActions from './TopicActions';
export default {
  name: 'TopicUpdate',
  props: ['activeUpdate', 'updatetopicId', 'topic', 'topicType'],
  data() {
    return {
      activity: false,
      topicName: this.topic,
      id: this.updatetopicId,
      type: this.topicType
    };
  },
  computed: {},
  methods: {
    close() {
      this.$vs.notify({
        color: 'danger',
        text: this.topicName + ' başlığı güncellenemedi.'
      });
      this.$emit('activityUpdate', false);
    },
    async submit() {
      //TOPIC GUNCELLEME ISLEMI
      if (confirm('Başlık güncellendikten sonra yayınlanacak. Emin misiniz?')) {
        let json = {
          id: this.id,
          topic: this.topicName
        };
        await TopicActions.updateTopic(json);
        await TopicActions.approveTopic(this.id);
        this.$vs.notify({
          color: 'success',
          text: this.topicName + ' başlığı güncellendi ve yayınlandı.'
        });
        this.$emit('activityUpdate', false);
        this.$emit('refreshData', this.type);
      } else {
        this.$vs.notify({
          color: 'danger',
          text: this.topicName + ' başlığı güncellenemedi.'
        });
      }
    }
  },
  watch: {
    activeUpdate(newData) {
      this.activity = newData;
    },
    updatetopicId(newData) {
      this.id = newData;
    },
    topic(newData) {
      this.topicName = newData;
    },
    topicType(newData) {
      this.type = newData;
    }
  }
};
</script>
